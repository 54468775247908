// SimpleDatafeed.ts - with client-side smoothing
import { io, Socket } from 'socket.io-client';
import {
  SocketEvents,
  WebSocketUpdate,
  Bar,
  SymbolDataRange,
  WatchData,
  ISimpleDatafeed,
  ExtendedSearchSymbolResultItem,
  ExtendedLibrarySymbolInfo,
} from './types';
import {
  IBasicDataFeed,
  LibrarySymbolInfo,
  SearchSymbolResultItem,
  ResolutionString,
  PeriodParams,
  HistoryCallback,
  DatafeedErrorCallback,
} from '@/public/charting_library-master/charting_library/charting_library';

export class SimpleDatafeed implements ISimpleDatafeed {
  public socket!: Socket;
  private currentSource: string = 'forum';
  private subscribers: { [key: string]: (bar: Bar) => void } = {};
  private cachedBars: { [key: string]: Bar[] } = {};
  private symbolDataRanges: {
    [key: string]: { firstDataPoint: number; lastDataPoint: number };
  } = {};

  private symbolIcons: { [key: string]: string } = {
    ROLEX: '/dashboard/rolex1.svg',
    OMEGA: '/dashboard/omega1.svg',
    'METAL:GOLD': '/dashboard/gold.svg',
    'INDEX:SP500': '/dashboard/sp500.svg',
    'INFLATION:CPI': '/dashboard/us-flag.svg',
    'INFLATION:RATE': '/dashboard/us-flag.svg',
    'EMPLOYMENT:UNRATE': '/dashboard/us-flag.svg',
    'INTEREST:FED': '/dashboard/us-flag.svg',
    'MACRO:GDP': '/dashboard/us-flag.svg',
    'FED:BALANCE': '/dashboard/us-flag.svg',
    'MONEY:M2': '/dashboard/us-flag.svg',
    'MONEY:BASE': '/dashboard/us-flag.svg',
  };

  constructor() {
    console.log('Starting SimpleDatafeed initialization');
    const socketUrl = 'http://localhost:3000';

    try {
      this.socket = io(socketUrl, {
        path: '/api/socketio',
        transports: ['websocket'],
        autoConnect: true,
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 20000,
      });

      console.log('Socket instance created with URL:', socketUrl);

      this.socket.on('connect', () => {
        console.log('Socket connected successfully:', {
          id: this.socket.id,
          url: socketUrl,
          connected: this.socket.connected,
        });
        this.setupSocketListeners();
        this.subscribeToAllUpdates();
      });

      this.socket.on('connect_error', (error) => {
        console.error('Socket connection error:', {
          error: error.message,
          socketUrl,
          socketId: this.socket?.id,
        });
      });
    } catch (error) {
      console.error('Error in SimpleDatafeed initialization:', error);
      this.socket = io(socketUrl, { autoConnect: false });
    }
  }

  // Add method to update the source
  public setCurrentSource(source: string): void {
    console.log('Changing data source to:', source);
    if (this.currentSource === source) {
      console.log('Source is already set to', source);
      return;
    }

    this.currentSource = source;
    this.cachedBars = {}; // Clear cached bars when source changes
    this.symbolDataRanges = {}; // Clear data ranges too

    // Notify all subscribers about the source change
    Object.keys(this.subscribers).forEach((symbol) => {
      this.subscribers[symbol]({
        time: Date.now(),
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        volume: 0,
      });
    });
  }

  public getCurrentSource(): string {
    return this.currentSource;
  }

  private setupSocketListeners(): void {
    if (!this.socket) {
      console.error('Cannot setup listeners - socket not initialized');
      return;
    }

    console.log('Setting up socket listeners');

    this.socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect' || reason === 'transport close') {
        setTimeout(() => this.socket.connect(), 1000);
      }
    });

    this.socket.on('dataUpdate', (data: any) => {
      console.log('Received data update:', data);
      this.handleDataUpdate(data);
    });

    this.socket.io.on('reconnect', (attempt) => {
      console.log('Socket reconnected after attempt:', attempt);
      this.subscribeToAllUpdates(); // Resubscribe after reconnection
    });
  }

  private getSymbolIcon(symbol: string): string {
    if (this.symbolIcons[symbol]) {
      return this.symbolIcons[symbol];
    }

    if (symbol.includes(':')) {
      const [category] = symbol.split(':');
      if (
        [
          'INFLATION',
          'EMPLOYMENT',
          'INTEREST',
          'MACRO',
          'FED',
          'MONEY',
          'INDEX',
        ].includes(category)
      ) {
        return '/dashboard/us-flag.svg';
      }
    }

    const brand = symbol.split(':')[0].toUpperCase();
    return this.symbolIcons[brand] || '/dashboard/default.svg';
  }

  private async fetchSymbolDataRange(
    symbol: string
  ): Promise<{ firstDataPoint: number; lastDataPoint: number }> {
    try {
      console.log(
        `Fetching data range for ${symbol} from source ${this.currentSource}`
      );

      // Check if we have this in cache
      const cachedRange = this.symbolDataRanges[symbol];
      if (cachedRange) {
        console.log('Using cached data range for', symbol);
        return cachedRange;
      }

      const url = `/api/trading-dashboard/getDataRange?symbol=${encodeURIComponent(symbol)}&source=${this.currentSource}`;
      console.log('Fetching data range from URL:', url);

      const response = await fetch(url);
      if (!response.ok) {
        console.error(
          `HTTP error fetching data range! status: ${response.status}`
        );
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const range = await response.json();
      console.log('Data range received:', range);

      if (
        range.firstDataPoint !== undefined &&
        range.lastDataPoint !== undefined
      ) {
        // Ensure the range is valid
        if (range.firstDataPoint > range.lastDataPoint) {
          console.warn('Invalid data range - first > last, swapping values');
          const temp = range.firstDataPoint;
          range.firstDataPoint = range.lastDataPoint;
          range.lastDataPoint = temp;
        }

        // Ensure timestamps are in seconds (rather than milliseconds)
        const firstPoint =
          range.firstDataPoint > 1e12
            ? Math.floor(range.firstDataPoint / 1000)
            : range.firstDataPoint;
        const lastPoint =
          range.lastDataPoint > 1e12
            ? Math.floor(range.lastDataPoint / 1000)
            : range.lastDataPoint;

        // Store the range in cache
        this.symbolDataRanges[symbol] = {
          firstDataPoint: firstPoint,
          lastDataPoint: lastPoint,
        };

        // Immediately trigger a getBars request to make sure data is loaded
        console.log(
          'Initiating followup getBars request after receiving data range'
        );
        try {
          const fromTime = firstPoint;
          const toTime = lastPoint;

          // Build direct getBars URL
          const barsUrl = `/api/trading-dashboard/getBars?symbol=${encodeURIComponent(symbol)}&from=${fromTime}&to=${toTime}&resolution=1D&source=${this.currentSource}`;
          console.log('Directly fetching bars from:', barsUrl);

          const barsResponse = await fetch(barsUrl);
          if (barsResponse.ok) {
            const barsData = await barsResponse.json();
            console.log(
              `Fetched ${barsData.length} bars directly for ${symbol}`
            );

            if (Array.isArray(barsData) && barsData.length > 0) {
              // Ensure time is in milliseconds format
              const processedBars = barsData.map((bar) => ({
                ...bar,
                time:
                  typeof bar.time === 'number' && bar.time < 1e12
                    ? bar.time * 1000
                    : bar.time,
              }));

              // Apply smoothing locally
              const smoothedBars = this.applySmoothingToBars(processedBars);

              // Cache the smoothed bars
              this.cachedBars[symbol] = smoothedBars;
            }
          }
        } catch (barsError) {
          console.warn('Failed to prefetch bars after data range:', barsError);
        }

        return this.symbolDataRanges[symbol];
      } else {
        console.error('Invalid data range format:', range);
        throw new Error('Invalid data range format');
      }
    } catch (error) {
      console.error('Error fetching data range:', error);
      // Provide a fallback range so the chart can still try to load
      return {
        firstDataPoint: Math.floor(Date.now() / 1000) - 31536000, // 1 year ago
        lastDataPoint: Math.floor(Date.now() / 1000),
      };
    }
  }

  onReady(callback: (configuration: object) => void): void {
    console.log('Datafeed onReady called');
    setTimeout(
      () =>
        callback({
          supported_resolutions: ['1D', '1W', '1M'] as ResolutionString[],
          supports_marks: false,
          supports_timescale_marks: false,
          supports_time: true,
          exchanges: [
            {
              value: 'WatchInspect',
              name: 'WatchInspect',
              desc: 'Watch Market Data',
            },
            {
              value: 'Economic',
              name: 'Economic',
              desc: 'Economic Indicators',
            },
          ],
          symbols_types: [
            { name: 'watch', value: 'watch' },
            { name: 'economic', value: 'economic' },
          ],
        }),
      0
    );
  }

  searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: (result: SearchSymbolResultItem[]) => void
  ): void {
    console.log('Searching symbols with input:', userInput);
    fetch('/api/trading-dashboard/watchData')
      .then((response) => response.json())
      .then((watchData: { data: WatchData[] }) => {
        const searchTerm = userInput.toLowerCase();
        const filteredSymbols = watchData.data
          .filter(
            (item) =>
              item.symbol.toLowerCase().includes(searchTerm) ||
              item.description.toLowerCase().includes(searchTerm) ||
              (item.subModel &&
                item.subModel.toLowerCase().includes(searchTerm))
          )
          .map(
            (item) =>
              ({
                symbol: item.symbol,
                full_name: item.fullName,
                description: item.description,
                subModel: item.subModel,
                exchange: item.exchange,
                ticker: item.ticker,
                type: item.type,
                dataCount: item.dataCount,
                exchange_logo: this.getSymbolIcon(item.symbol),
              }) as ExtendedSearchSymbolResultItem
          );

        filteredSymbols.sort((a, b) => (b.dataCount || 0) - (a.dataCount || 0));
        console.log(`Found ${filteredSymbols.length} matching symbols`);
        onResult(filteredSymbols);
      })
      .catch((error) => {
        console.error('Error searching symbols:', error);
        onResult([]);
      });
  }

  async resolveSymbol(
    symbolName: string,
    onResolve: (symbolInfo: ExtendedLibrarySymbolInfo) => void,
    onError: (reason: string) => void
  ): Promise<void> {
    console.log(`Resolving symbol: ${symbolName}`);
    try {
      // Special case for ROLEX:INDEX
      if (symbolName === 'ROLEX:INDEX') {
        console.log('Resolving special ROLEX:INDEX symbol');

        const indexSymbolInfo: ExtendedLibrarySymbolInfo = {
          name: 'ROLEX:INDEX',
          ticker: 'ROLEX:INDEX',
          description: 'Rolex Market Index',
          type: 'index',
          session: '24x7',
          timezone: 'Etc/UTC',
          minmov: 1,
          pricescale: 100,
          has_intraday: false,
          has_weekly_and_monthly: true,
          supported_resolutions: ['1D', '1W', '1M'] as ResolutionString[],
          volume_precision: 0,
          data_status: 'streaming',
          exchange_logo: this.getSymbolIcon('ROLEX'),
          exchange: 'WatchInspect',
          listed_exchange: 'WatchInspect',
          format: 'price',
          data: { source: 'index' }, // Special source indicator
          visible_plots_set: 'ohlc',
        };

        onResolve(indexSymbolInfo);

        // Immediately pre-load the data in background
        this.initializeDataForSymbol('ROLEX:INDEX').catch((err) =>
          console.warn('Failed to pre-load ROLEX:INDEX data:', err)
        );

        return;
      }

      // Remove any "WatchInspect:" prefix if present
      const cleanSymbol = symbolName.replace('WatchInspect:', '');

      console.log('Resolving symbol:', cleanSymbol);

      // Create a default symbol info in case the API fails
      const defaultSymbolInfo: ExtendedLibrarySymbolInfo = {
        name: cleanSymbol,
        ticker: cleanSymbol,
        description: cleanSymbol.split(':')[1] || cleanSymbol,
        type: 'watch',
        session: '24x7',
        timezone: 'Etc/UTC',
        minmov: 1,
        pricescale: 100,
        has_intraday: false,
        has_weekly_and_monthly: true,
        supported_resolutions: ['1D', '1W', '1M'] as ResolutionString[],
        volume_precision: 0,
        data_status: 'streaming',
        exchange_logo: this.getSymbolIcon(cleanSymbol),
        exchange: 'WatchInspect',
        listed_exchange: 'WatchInspect',
        format: 'price',
        data: { source: this.currentSource || 'forum' },
      };

      try {
        // Try to fetch the real symbol info
        const response = await fetch(
          `/api/trading-dashboard/symbolInfo?symbol=${encodeURIComponent(cleanSymbol)}`
        );

        if (response.ok) {
          const data = await response.json();
          const symbolInfo: ExtendedLibrarySymbolInfo = {
            ...defaultSymbolInfo,
            ...data,
            name: cleanSymbol, // Ensure clean symbol is used
            exchange_logo: this.getSymbolIcon(cleanSymbol),
            data: { source: this.currentSource || 'forum' },
          };

          console.log('Symbol successfully resolved:', symbolInfo.name);
          onResolve(symbolInfo);
        } else {
          console.warn(
            `Symbol info fetch failed with status ${response.status}, using default info`
          );
          onResolve(defaultSymbolInfo);
        }
      } catch (fetchError) {
        console.warn(
          'Error fetching symbol info, using default info:',
          fetchError
        );
        onResolve(defaultSymbolInfo);
      }

      // Pre-fetch the data range in the background to ensure it's available when getBars is called
      this.fetchSymbolDataRange(cleanSymbol).catch((error) => {
        console.warn('Could not pre-fetch data range:', error);
      });
    } catch (error) {
      console.error('Error resolving symbol:', error);
      onError('Failed to resolve symbol');
    }
  }

  // Helper function to get the start of the week (Monday) for a timestamp
  private getWeekStartTime(timestamp: number): number {
    const date = new Date(timestamp);
    date.setUTCHours(0, 0, 0, 0);
    // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
    const day = date.getUTCDay();
    // Calculate how many days to subtract to get to Monday (or previous Monday)
    const diff = date.getUTCDate() - day + (day === 0 ? -6 : 1);
    date.setUTCDate(diff);
    return date.getTime();
  }

  // Calculate Hull Moving Average (HMA) for smooth data like in Charts page
  private calculateHMA(prices: number[], period: number = 20): number[] {
    if (!prices || prices.length === 0) return [];
    if (prices.length === 1) return [prices[0]];

    // Adjust period if too large
    period = Math.min(period, Math.floor(prices.length / 2));
    if (period < 2) period = 2;

    // HMA calculation
    const weightedMA = (values: number[], length: number): number[] => {
      const result = new Array(values.length).fill(0);
      for (let i = 0; i < values.length; i++) {
        let sum = 0;
        let weight = 0;
        for (let j = Math.max(0, i - length + 1); j <= i; j++) {
          const w = j - (i - length + 1) + 1;
          sum += values[j] * w;
          weight += w;
        }
        result[i] = sum / weight;
      }
      return result;
    };

    const halfPeriod = Math.floor(period / 2);
    const sqrtPeriod = Math.floor(Math.sqrt(period));

    // Calculate WMA with period/2
    const halfWMA = weightedMA(prices, halfPeriod);

    // Calculate WMA with period
    const fullWMA = weightedMA(prices, period);

    // Calculate 2 * WMA(period/2) - WMA(period)
    const rawHMA = halfWMA.map((value, i) => 2 * value - fullWMA[i]);

    // Calculate WMA with sqrt(period) of the above result
    return weightedMA(rawHMA, sqrtPeriod);
  }

  // Apply exponential smoothing to make lines smoother (similar to Charts page)
  private applyExponentialSmoothing(
    prices: number[],
    alpha: number = 0.15
  ): number[] {
    if (!prices || prices.length === 0) return [];
    if (prices.length === 1) return [prices[0]];

    const result = [prices[0]];
    for (let i = 1; i < prices.length; i++) {
      result.push(alpha * prices[i] + (1 - alpha) * result[i - 1]);
    }

    return result;
  }

  // Apply smoothing to price bars to match Charts page
  private applySmoothingToBars(bars: Bar[]): Bar[] {
    if (!bars || bars.length === 0) return [];

    // Extract close prices for smoothing
    const closePrices = bars.map((bar) => bar.close);

    // Apply Hull Moving Average for main smoothing
    const hmaSmoothed = this.calculateHMA(closePrices);

    // Apply additional exponential smoothing for super smooth curves
    const fullySmoothed = this.applyExponentialSmoothing(hmaSmoothed);

    // Return new bars with smoothed close prices
    return bars.map((bar, i) => ({
      ...bar,
      close: fullySmoothed[i] || hmaSmoothed[i] || bar.close,
    }));
  }

  private static ECONOMIC_PREFIXES = [
    'INFLATION:',
    'INTEREST:',
    'INDEX:',
    'METAL:',
    'EMPLOYMENT:',
    'MACRO:',
    'FED:',
    'MONEY:',
  ];

  // Aggregate bars to weekly - with smoothing
  private aggregateToWeekly(bars: Bar[]): Bar[] {
    const weeklyBars = new Map<
      number,
      {
        open: number;
        high: number;
        low: number;
        close: number;
        volume: number;
        firstTime: number;
        prices: number[];
      }
    >();

    bars.forEach((bar) => {
      // Get the start of the week (Monday) for the current bar
      const date = new Date(bar.time);
      date.setUTCHours(0, 0, 0, 0);
      // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
      const day = date.getUTCDay();
      // Calculate how many days to subtract to get to Monday (or previous Monday)
      const diff = date.getUTCDate() - day + (day === 0 ? -6 : 1);
      date.setUTCDate(diff);
      const weekKey = date.getTime();

      if (!weeklyBars.has(weekKey)) {
        weeklyBars.set(weekKey, {
          open: bar.open,
          high: bar.high,
          low: bar.low,
          close: bar.close,
          volume: bar.volume,
          firstTime: bar.time,
          prices: [bar.close],
        });
      } else {
        const existing = weeklyBars.get(weekKey)!;
        existing.high = Math.max(existing.high, bar.high);
        existing.low = Math.min(existing.low, bar.low);
        existing.close = bar.close;
        existing.volume += bar.volume;
        existing.prices.push(bar.close);
      }
    });

    // Process the weekly bars and apply smoothing
    const result = Array.from(weeklyBars.entries())
      .map(([weekStart, data]) => {
        // Apply Hull Moving Average for smoother charts
        let smoothedPrice = data.close;
        if (data.prices.length > 3) {
          const smoothedPrices = this.calculateHMA(data.prices);
          smoothedPrice = smoothedPrices[smoothedPrices.length - 1];
        }

        return {
          time: weekStart,
          open: data.open,
          high: data.high,
          low: data.low,
          close: smoothedPrice,
          volume: data.volume,
        };
      })
      .sort((a, b) => a.time - b.time);

    return result;
  }

  // Aggregate bars to monthly - with smoothing
  private aggregateToMonthly(bars: Bar[]): Bar[] {
    const monthlyBars = new Map<
      number,
      {
        open: number;
        high: number;
        low: number;
        close: number;
        volume: number;
        prices: number[];
      }
    >();

    bars.forEach((bar) => {
      const date = new Date(bar.time);
      const monthKey = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1);

      if (!monthlyBars.has(monthKey)) {
        monthlyBars.set(monthKey, {
          open: bar.open,
          high: bar.high,
          low: bar.low,
          close: bar.close,
          volume: bar.volume,
          prices: [bar.close],
        });
      } else {
        const existing = monthlyBars.get(monthKey)!;
        existing.high = Math.max(existing.high, bar.high);
        existing.low = Math.min(existing.low, bar.low);
        existing.close = bar.close;
        existing.volume += bar.volume;
        existing.prices.push(bar.close);
      }
    });

    // Process monthly bars and apply smoothing
    const result = Array.from(monthlyBars.entries())
      .map(([monthStart, data]) => {
        // Apply Hull Moving Average for smoother charts
        let smoothedPrice = data.close;
        if (data.prices.length > 3) {
          const smoothedPrices = this.calculateHMA(data.prices);
          smoothedPrice = smoothedPrices[smoothedPrices.length - 1];
        }

        return {
          time: monthStart,
          open: data.open,
          high: data.high,
          low: data.low,
          close: smoothedPrice,
          volume: data.volume,
        };
      })
      .sort((a, b) => a.time - b.time);

    return result;
  }

  async getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: DatafeedErrorCallback
  ): Promise<void> {
    console.log('🔍 getBars ENTRY POINT for symbol:', symbolInfo.name);
    console.log('Resolution:', resolution);
    console.log('Period params:', periodParams);

    // Extract the symbol from symbolInfo
    const symbol = symbolInfo.name;
    const source = this.currentSource;

    console.log('🔍 API parameters:', {
      symbol,
      from: periodParams.from,
      to: periodParams.to,
      resolution,
      source,
    });

    try {
      // SPECIAL CASE: Handle Rolex Index specifically
      if (symbol === 'ROLEX:INDEX') {
        console.log(
          '🔍 Special handling for ROLEX:INDEX - using custom endpoint'
        );

        try {
          // Use the timeframe parameter if possible
          const timeframeParam = this.mapResolutionToTimeframe(resolution);
          const url = `/api/charts/rolex-index?timeframe=${timeframeParam}`;

          console.log('Requesting ROLEX:INDEX data from URL:', url);

          const response = await fetch(url);

          if (!response.ok) {
            console.error(
              `Error fetching ROLEX:INDEX data: ${response.status}`
            );
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const indexData = await response.json();
          console.log(
            `Received ${indexData.length} data points for ROLEX:INDEX`
          );

          if (!Array.isArray(indexData) || indexData.length === 0) {
            console.warn('No data returned for ROLEX:INDEX');
            onHistoryCallback([], { noData: true });
            return;
          }

          // Transform the index data to TradingView bar format
          const bars = indexData.map((point) => ({
            time: new Date(point.date).getTime(),
            open: point.price,
            high: point.price,
            low: point.price,
            close: point.price,
            volume: 0,
          }));

          // Cache the bars for later use
          this.cachedBars[symbol] = bars;

          // Return all bars in requested range
          const filteredBars = bars.filter((bar) => {
            const barTime = Math.floor(bar.time / 1000);
            return barTime >= periodParams.from && barTime <= periodParams.to;
          });

          console.log(`Returning ${filteredBars.length} ROLEX:INDEX bars`);
          onHistoryCallback(filteredBars, {
            noData: filteredBars.length === 0,
          });
          return;
        } catch (indexError) {
          console.error('Error fetching ROLEX:INDEX data:', indexError);
          // If there's an error with the index, we'll fall back to normal processing
          console.log('Falling back to normal data processing for ROLEX:INDEX');
        }
      }

      // Standard processing continues as before...

      // First check if we have cached bars for this symbol
      if (this.cachedBars[symbol] && this.cachedBars[symbol].length > 0) {
        console.log(
          `Using ${this.cachedBars[symbol].length} cached bars for ${symbol}`
        );
        // Filter the cached bars based on the requested time range
        const filteredBars = this.cachedBars[symbol].filter((bar) => {
          // Handle both seconds and milliseconds formats
          const barTimeInSeconds =
            typeof bar.time === 'number' && bar.time > 1e12
              ? Math.floor(bar.time / 1000)
              : bar.time;
          return (
            barTimeInSeconds >= periodParams.from &&
            barTimeInSeconds <= periodParams.to
          );
        });

        if (filteredBars.length > 0) {
          console.log(`Returning ${filteredBars.length} filtered cached bars`);

          // Apply resolution-specific processing
          let resultBars = filteredBars;
          if (resolution === '1W') {
            resultBars = this.aggregateToWeekly(filteredBars);
          } else if (resolution === '1M') {
            resultBars = this.aggregateToMonthly(filteredBars);
          }

          onHistoryCallback(resultBars, { noData: false });
          return;
        }
        console.log(
          'No cached bars match the requested time range, fetching from API'
        );
      }

      // Get or create data range
      if (!this.symbolDataRanges[symbol]) {
        console.log('No data range available for', symbol, 'fetching now...');
        try {
          await this.fetchSymbolDataRange(symbol);
          console.log('Successfully fetched data range');
        } catch (rangeError) {
          console.warn(
            'Failed to fetch data range, but continuing with request:',
            rangeError
          );
        }
      }

      // Ensure from/to are valid Unix timestamps (in seconds)
      const fromTimestamp =
        typeof periodParams.from === 'number'
          ? periodParams.from
          : Math.floor(Date.now() / 1000) - 365 * 24 * 60 * 60;

      // Ensure we don't request future data
      const currentTime = Math.floor(Date.now() / 1000);
      const toTimestamp =
        typeof periodParams.to === 'number'
          ? Math.min(periodParams.to, currentTime)
          : currentTime;

      console.log('Adjusted time range to avoid future data:', {
        original: periodParams.to,
        adjusted: toTimestamp,
        currentTime,
      });

      // Build URL with all parameters
      const url = `/api/trading-dashboard/getBars?symbol=${encodeURIComponent(symbol)}&from=${fromTimestamp}&to=${toTimestamp}&resolution=${resolution}&source=${source}`;

      console.log('🔍 getBars API called');
      console.log('Requesting bars from URL:', url);

      // Set up timeout for fetch
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 second timeout

      try {
        const response = await fetch(url, { signal: controller.signal });
        clearTimeout(timeoutId);

        console.log('Response status for getBars:', response.status);

        if (!response.ok) {
          console.error('Error fetching bars:', response.statusText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(
          'getBars data received:',
          data && Array.isArray(data)
            ? `${data.length} bars`
            : 'No data or error'
        );

        if (!data || data.error || (Array.isArray(data) && data.length === 0)) {
          console.warn('No data returned for symbol:', symbol);
          onHistoryCallback([], { noData: true }); // Return noData true when no data from API
          return;
        }

        // Ensure data is an array
        if (!Array.isArray(data)) {
          console.error('API returned non-array data:', data);
          throw new Error('API returned invalid data format');
        }

        // Process the bars to ensure time is in milliseconds
        const rawBars = data.map((bar) => {
          // Ensure bar time is consistently in milliseconds
          let barTime = bar.time;
          if (typeof barTime === 'number' && barTime < 1e12) {
            barTime = barTime * 1000; // Convert seconds to milliseconds
          }

          return {
            ...bar,
            time: barTime,
            open: Number(bar.open),
            high: Number(bar.high),
            low: Number(bar.low),
            close: Number(bar.close),
            volume: Number(bar.volume || 0),
          };
        });

        // Apply additional smoothing to match Charts page
        const smoothedBars = this.applySmoothingToBars(rawBars);

        // Apply resolution-specific processing
        let finalBars = smoothedBars;
        if (resolution === '1W') {
          finalBars = this.aggregateToWeekly(smoothedBars);
        } else if (resolution === '1M') {
          finalBars = this.aggregateToMonthly(smoothedBars);
        }

        // Store data range
        if (finalBars.length > 0) {
          if (!this.symbolDataRanges[symbol]) {
            this.symbolDataRanges[symbol] = {
              firstDataPoint: Number.MAX_SAFE_INTEGER,
              lastDataPoint: 0,
            };
          }

          this.symbolDataRanges[symbol].firstDataPoint = Math.min(
            this.symbolDataRanges[symbol].firstDataPoint,
            Math.floor(finalBars[0].time / 1000) // Convert to seconds for storage
          );

          this.symbolDataRanges[symbol].lastDataPoint = Math.max(
            this.symbolDataRanges[symbol].lastDataPoint,
            Math.floor(finalBars[finalBars.length - 1].time / 1000) // Convert to seconds for storage
          );

          // Cache all bars for this symbol (the smoothed version)
          this.cachedBars[symbol] = smoothedBars;
        }

        console.log(`Returning ${finalBars.length} bars for symbol: ${symbol}`);
        onHistoryCallback(finalBars, { noData: finalBars.length === 0 });
      } catch (fetchError) {
        clearTimeout(timeoutId);
        console.warn(`Error fetching bars for ${symbol}:`, fetchError);
        onHistoryCallback([], { noData: true }); // Return noData true on fetch error
      }
    } catch (error) {
      console.error('Error in getBars:', error);
      onErrorCallback('Error fetching data');
    }
  }

  subscribeBars(
    symbolInfo: ExtendedLibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: (bar: Bar) => void,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ): void {
    this.subscribers[symbolInfo.name] = onTick;
    this.socket.emit('subscribeTicker', symbolInfo.name);
  }

  unsubscribeBars(listenerGuid: string): void {
    const symbol = Object.keys(this.subscribers).find(
      (key) => this.subscribers[key] === this.subscribers[listenerGuid]
    );
    if (symbol) {
      delete this.subscribers[symbol];
      this.socket.emit('unsubscribeTicker', symbol);
    }
  }

  private handleDataUpdate(change: any): void {
    if (
      change.operationType === 'insert' ||
      change.operationType === 'update'
    ) {
      const symbol = `${change.fullDocument.brand}:${change.fullDocument.models[0].name}`;
      const latestListing =
        change.fullDocument.models[0].listings[
          change.fullDocument.models[0].listings.length - 1
        ];

      if (latestListing && this.subscribers[symbol]) {
        const bar: Bar = {
          time: latestListing.data[0] * 1000,
          open: latestListing.data[1],
          high: latestListing.data[1],
          low: latestListing.data[1],
          close: latestListing.data[1],
          volume: 0,
        };
        this.subscribers[symbol](bar);
      }
    }
  }

  subscribeToAllUpdates(): void {
    console.log('Subscribing to all updates');
    this.socket.emit('subscribeToAllUpdates');
  }

  // Helper method to get icon URL
  getIconUrl(symbol: string): string {
    // Convert to uppercase for consistent lookup
    const uppercaseSymbol = symbol.toUpperCase();

    // First check if we have a direct match
    if (this.symbolIcons[uppercaseSymbol]) {
      return this.symbolIcons[uppercaseSymbol];
    }

    // Check for partial matches (useful for formats like ROLEX:16610)
    for (const key in this.symbolIcons) {
      if (uppercaseSymbol.startsWith(key)) {
        return this.symbolIcons[key];
      }
    }

    // Default icon
    return '/dashboard/default.svg';
  }

  // Helper method to directly fetch bars for a symbol
  private async fetchBarsDirectly(symbol: string): Promise<Bar[]> {
    console.log(`Directly fetching bars for ${symbol}`);
    try {
      // Generate comprehensive time range based on possible timeframes
      const now = Math.floor(Date.now() / 1000);
      const tenYearsAgo = now - 10 * 365 * 24 * 60 * 60;

      // Use the provided range or default to a sensible range
      const range = this.symbolDataRanges[symbol] || {
        firstDataPoint: tenYearsAgo,
        lastDataPoint: now,
      };

      // Ensure we're not requesting future data
      const adjustedEnd = Math.min(range.lastDataPoint, now);

      // Build URL to fetch data
      const url = `/api/trading-dashboard/getBars?symbol=${encodeURIComponent(symbol)}&from=${range.firstDataPoint}&to=${adjustedEnd}&resolution=1D&source=${this.currentSource}`;
      console.log(`Direct fetch URL: ${url}`);

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        console.error('Invalid data format from getBars API:', data);
        return []; // Return empty array when invalid data
      }

      console.log(`Fetched ${data.length} bars directly for ${symbol}`);

      // Make sure time is in milliseconds format
      const rawBars = data.map((bar) => ({
        ...bar,
        time:
          typeof bar.time === 'number' && bar.time < 1e12
            ? bar.time * 1000
            : bar.time,
        open: Number(bar.open),
        high: Number(bar.high),
        low: Number(bar.low),
        close: Number(bar.close),
        volume: Number(bar.volume || 0),
      }));

      // Apply smoothing to match Charts page
      return this.applySmoothingToBars(rawBars);
    } catch (error) {
      console.error(`Error fetching bars directly for ${symbol}:`, error);
      return []; // Return empty array on error
    }
  }
  // Add this helper method to SimpleDatafeed class
  private mapResolutionToTimeframe(resolution: string): string {
    switch (resolution) {
      case '1D':
        return '1m'; // 1 month for daily view
      case '1W':
        return '6m'; // 6 months for weekly view
      case '1M':
        return 'all'; // All data for monthly view
      default:
        return 'all';
    }
  }

  // Also make sure to add special handling in initializeDataForSymbol
  public async initializeDataForSymbol(symbol: string): Promise<Bar[] | null> {
    console.log(`🔍 Manually initializing data for symbol: ${symbol}`);

    // Special handling for ROLEX:INDEX
    if (symbol === 'ROLEX:INDEX') {
      console.log('Initializing ROLEX:INDEX using custom endpoint');
      try {
        const response = await fetch('/api/charts/rolex-index?timeframe=all');

        if (!response.ok) {
          console.error(`Error fetching ROLEX:INDEX data: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const indexData = await response.json();

        if (!Array.isArray(indexData) || indexData.length === 0) {
          console.warn('No data returned for ROLEX:INDEX');
          return [];
        }

        // Transform the index data to TradingView bar format
        const bars = indexData.map((point) => ({
          time: new Date(point.date).getTime(),
          open: point.price,
          high: point.price,
          low: point.price,
          close: point.price,
          volume: 0,
        }));

        // Cache the bars for later use
        this.cachedBars[symbol] = bars;
        console.log(`Pre-initialized ${bars.length} bars for ROLEX:INDEX`);
        return bars;
      } catch (error) {
        console.error('Error initializing ROLEX:INDEX data:', error);
        return []; // Return empty array on error
      }
    }

    // Continue with standard initialization for other symbols...
    return new Promise((resolve, reject) => {
      this.resolveSymbol(
        symbol,
        async (symbolInfo) => {
          try {
            // First, try to get data range if not already cached
            if (!this.symbolDataRanges[symbol]) {
              await this.fetchSymbolDataRange(symbol);
            }

            // Force direct fetch of bars even before the widget requests them
            const bars = await this.fetchBarsDirectly(symbol);
            if (bars.length > 0) {
              this.cachedBars[symbol] = bars;
              console.log(
                `✅ Pre-initialized ${bars.length} bars for ${symbol}`
              );
              resolve(bars);
              return;
            }

            // If direct fetch failed, fall back to the normal getBars
            const now = Math.floor(Date.now() / 1000);
            const from = now - 60 * 60 * 24 * 365 * 15; // Use 15 years of data

            this.getBars(
              symbolInfo,
              '1D' as ResolutionString,
              { from, to: now, countBack: 5000, firstDataRequest: true },
              (bars) => {
                console.log(`✅ Initialized ${bars.length} bars for ${symbol}`);
                resolve(bars as any);
              },
              (error) => {
                console.error(
                  `❌ Failed to initialize bars for ${symbol}:`,
                  error
                );
                resolve([]); // Resolve with empty array on error
              }
            );
          } catch (error) {
            console.error(`Error in initializeDataForSymbol:`, error);
            resolve([]); // Resolve with empty array on error
          }
        },
        (error) => {
          console.error(`❌ Failed to resolve symbol ${symbol}:`, error);
          resolve([]); // Resolve with empty array on symbol resolution error
        }
      );
    });
  }
}